import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import QR from './QR';
import Home from "./Home";


function App() {

  return <BrowserRouter>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/:qr" element={<QR />} />
    </Routes>
  </BrowserRouter>
}

export default App;
