import './App.css';


function Home() {

    return (
        <div>
            <header className="App-header">
                <div>
                    <div style={{
                        fontSize: '28px',
                        fontWeight: 400,
                        color: '#41525d',
                    }}>QR SCANNER</div>
                </div>
            </header >
        </div >
    );
}

export default Home;
